.home {
  height: 92%;
}

@media (max-width: 700px) {
  .home {
    height: auto;
  }
}

.contact-me-footer {
  width: 90%;
}